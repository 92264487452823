import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import RoundLink from "components/common/RoundLink";
import IntegrationsForm from "components/modals/IntegrationsForm";
import PageContent from "components/page/PageContent";
import { css } from "linaria";
import React, { useState } from "react";
import { Converter } from "showdown";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import leftIdleArrow from "./img/left-idle.png";
import leftSelectedArrow from "./img/left-selected.png";
import rightIdleArrow from "./img/right-idle.png";
import rightSelectedArrow from "./img/right-selected.png";
import BackLink from "components/common/BackLink";

const IntegrationContainer = css`
  display: flex;
  gap: 4rem;
  padding: 4rem 0;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

const ContentContainer = css`
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  gap: 8rem;
  h3 {
    margin-bottom: 1rem;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 1024px) {
    gap: 4rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContent = css`
  height: 100%;
  width: 60%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SideContent = css`
  height: 100%;
  width: 40%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TitleSection = css`
  display: flex;
  gap: 35px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const IconWrapper = css`
  background-color: var(--white);
  height: 100px;
  width: 100px;
  aspect-ratio: 1;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 600px) {
    height: 48px;
    width: 48px;
  }
`;

const AppIcon = css`
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1;
  object-position: center;
`;

const TitleColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  span,
  h2 {
    font-size: 16px;
    font-weight: 400;
    max-width: 468px;
  }
  span {
    margin-left: 13px;
    @media (max-width: 1024px) {
      margin: 0;
    }
  }
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 44px;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

const CarouselSection = css`
  width: 100%;
  .swiper-button-prev,
  .swiper-button-next {
    color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 250ms;
  }
  .swiper-button-prev {
    background-image: url(${leftIdleArrow});
    :hover {
      background-image: url(${leftSelectedArrow});
    }
  }
  .swiper-button-next {
    background-image: url(${rightIdleArrow});
    :hover {
      background-image: url(${rightSelectedArrow});
    }
  }
`;

const CarouselImage = css`
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const AboutSection = css``;

const BenefitsSection = css`
  ul {
    padding-left: 1rem;
  }
`;

const LearnMoreSection = css`
  width: fit-content;
  height: 25%;
  display: flex;
  align-items: flex-end;
`;

const CategorySection = css``;

const WorksWithSection = css`
  .chip-row {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
  }
`;

const Chip = css`
  background-color: #eee;
  border-radius: 68px;
  font-size: 16px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ResourcesSection = css`
  .resource-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      font-size: 16px;
      line-height: 2;
      text-decoration: none;
      text-transform: capitalize;
      color: var(--blue-500);
      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 700px) {
        font-size: 14px;
      }
    }
  }
`;

const CarouselWrapper = css`
  border: 1px solid #eee;
  background-color: var(--white);
  border-radius: 12px;
  aspect-ratio: 1.91/1;
  width: 100%;
  max-width: 1200px;
  z-index: 1;
`;

const body = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const IntegrationPage = ({
  appIcon,
  appName,
  companyName,
  shortDescription,
  appListing,
  categories,
  worksWith,
  longDescription,
  benefits,
  resources,
}) => {
  const [open, setOpen] = useState(false);
  const converter = new Converter();
  const html = converter.makeHtml(longDescription);

  const scrollToModel = () => {
    const references = document.querySelector("#modal");
    references.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleModalOpen = async () => {
    await setOpen(true);
    scrollToModel();
  };

  const handleResourceURL = url => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail = emailRegex.test(url);
    if (isEmail) {
      return `mailto:${url}`;
    }
    return url;
  };

  return (
    <PageContent>
      <div className={IntegrationContainer}>
        {open ? (
          <IntegrationsForm
            setOpen={setOpen}
            formId="d436a52a-1f59-48cc-9116-15417435177f"
            company={appName}
          />
        ) : (
          <></>
        )}

        <BackLink path="/integrations/" text="Browse Apps" />
        <div className={TitleSection}>
          <div className={IconWrapper}>
            <img src={appIcon} alt={appName} className={AppIcon} />
          </div>
          <div className={TitleColumn}>
            <h1>
              {appName}
              <span>by {companyName}</span>
            </h1>
            <h2>{shortDescription}</h2>
          </div>
        </div>
        <div className={ContentContainer}>
          <div className={MainContent}>
            <div className={CarouselSection}>
              <Swiper
                navigation
                pagination={{ clickable: true }}
                slidesPerView={1}
                loop
                modules={[Navigation, Pagination]}
                className={CarouselWrapper}
              >
                {appListing.map(slide => (
                  <SwiperSlide>
                    <img
                      src={slide.appListingImage}
                      alt={slide.imageAlt}
                      className={CarouselImage}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className={AboutSection}>
              <h3>About {appName}</h3>
              <div
                className={body}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            <div className={BenefitsSection}>
              <h3>Integration Benefits</h3>
              <ul>
                {benefits &&
                  benefits.map(benefit => (
                    <li>
                      <p>{benefit}</p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className={SideContent}>
            <div className={LearnMoreSection}>
              <RoundLink appearance="gradientFill" onClick={handleModalOpen}>
                Learn more
              </RoundLink>
            </div>
            <div className={CategorySection}>
              <h3>Category</h3>
              {categories && categories.map(item => <p>{item}</p>)}
            </div>
            <div className={WorksWithSection}>
              <h3>Works With</h3>
              <div className="chip-row">
                {worksWith &&
                  worksWith.map(item => <div className={Chip}>{item}</div>)}
              </div>
            </div>
            <div className={ResourcesSection}>
              <h3>Resources</h3>
              <ul className="resource-list">
                {resources &&
                  resources.map(item => (
                    <a
                      href={handleResourceURL(item.resourceUrl)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.resourceText}
                    </a>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default IntegrationPage;
