import React from "react";
import { css } from "linaria";
import Close from "../page/img/close.png";
import RhombusForm from "components/form/RhombusForm";

const modalContainer = css`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  backdrop-filter: blur(1px);
`;

const modalBox = css`
  border: 1px solid #d9d9d9;
  background: var(--white);
  border-radius: 12px;
  padding: 4rem;
  width: 483px;
  min-height: 468px;
  filter: drop-shadow(0.1rem 0.1rem 0.75rem #00000030);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  scroll-margin-top: 13rem;
  gap: 20px;

  h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 44px;
  }

  @media (min-width: 1440px) {
    min-width: 600px;
  }
`;

const closeBtn = css`
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  transition: all 250ms;
  :hover {
    background-color: #eee;
  }
`;

const IntegrationsForm = ({ setOpen, formId, company }) => {
  return (
    <div className={modalContainer}>
      <div className={modalBox} onClick={e => e.stopPropagation()} id="modal">
        <button
          className={closeBtn}
          onClick={() => {
            setOpen(false);
          }}
        >
          <img src={Close} alt="close" />
        </button>
        <h2>Let&apos;s Connect</h2>
        <p>Learn how {company} integrates with Rhombus</p>
        <RhombusForm formId={formId} />
      </div>
    </div>
  );
};

export default IntegrationsForm;
